import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ElastiCache for Redis 運用小話 〜メドレー・ TechLunch〜",
  "date": "2018-05-25T03:00:00.000Z",
  "slug": "entry/2018/05/25/120000",
  "tags": ["medley"],
  "hero": "./2018_05_25.png",
  "heroAlt": "es"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の後藤です。医療介護の求人サイト「ジョブメドレー」の開発を担当しています。`}</p>
    <p>{`ジョブメドレーでは各種キャッシュや `}<a parentName="p" {...{
        "href": "https://github.com/mperham/sidekiq"
      }}>{`sidekiq`}</a>{` の queue 等に`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/elasticache/redis/"
      }}>{`ElastiCache for Redis`}</a>{`を利用しています。`}</p>
    <p>{`先日、メドレーで定期開催している社内勉強会 TechLunch にて、ジョブメドレーでの ElastiCache for Redis の運用周りのネタや知見について発表しました。本記事では、その中から抜粋してメモリ周りの話について紹介します。`}</p>
    <h1>{`キー削除周りの仕様について`}</h1>
    <p>{`キャッシュとして ElastiCache for Redis を利用していく上でまず把握しておきたいのが、キーの削除周りの仕様です。`}</p>
    <h2>{`Redis 本家の Expiration/Eviction`}</h2>
    <p>{`Expiration については Redis ではキーに TTL を設定することで有効期限を設定することができ、`}<a parentName="p" {...{
        "href": "https://redis.io/commands/expire#how-redis-expires-keys"
      }}>{`こちら`}</a>{` に記載のロジックで削除処理を実施してくれます。`}</p>
    <p>{`Eviction については Redis 本家の実装では以下のような仕様になっています。Redis 本家ドキュメントは`}<a parentName="p" {...{
        "href": "https://redis.io/topics/lru-cache"
      }}>{`こちら`}</a>{`。`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`used_memory`}</code>{`（アプリが利用しているメモリ量）が`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`maxmemory`}</code>{`値を超え始めたら Eviction が発火し始める`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`maxmemory`}</code>{`は `}<em parentName="li">{`redis.conf`}</em>{` 指定か `}<a parentName="li" {...{
              "href": "https://redis.io/commands/config-set"
            }}><code parentName="a" {...{
                "className": "language-text"
              }}>{`CONFIG SET`}</code></a>{`コマンドで設定できる`}</li>
        </ul>
      </li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`maxmemory`}</code>{`に到達したらどのように振る舞うべきかを`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`maxmemory-policy`}</code>{`で指定`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`noeviction`}</code>{`: 容量が必要なオペレーションでは evit せず、エラーとなる`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`allkeys-lru`}</code>{`: 常に LRU アルゴリズムで削除対象選定`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`volatile-lru`}</code>{`: TTL が設定されたキー内で LRU アルゴリズムで削除対象選定`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`allkeys-random`}</code>{`: 常にランダムに削除対象選定して削除`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`volatile-random`}</code>{`: TTL が設定されたキー内でランダムに削除対象選定`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`volatile-ttl`}</code>{`: TTL が設定されたキー内で TTL`}</li>
        </ul>
      </li>
    </ul>
    <p>{`また、ElastiCache for Redis ではまだ 3 系列までしか使えませんが、4 系列では新たに`}<a parentName="p" {...{
        "href": "https://antirez.com/news/109"
      }}>{`LFU`}</a>{`も選択肢に入ってくるようです。`}</p>
    <p>{`Redis 本家の Eviction の動作仕様としては以下のイメージです。`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/antirez/redis/blob/3.2.10/src/server.c#L3343-L3357"
      }}>{`https://github.com/antirez/redis/blob/3.2.10/src/server.c#L3343-L3357`}</a></p>
    <p>{`ElastiCache for Redis では`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`maxmemory`}</code>{`は編集できず、その代わりに後述する `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reserved-memory`}</code>{` （`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reserved-memory-percent`}</code>{`）を設定すると Eviction が発火するメモリ量の閾値が変わることから上記周りの実装は AWS 側で手を入れていそうです。`}</p>
    <h2>{`ElastiCache for Redis での Eviction`}</h2>
    <p>{`ElastiCache for Redis では以下のように Redis 本家にはない`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reserved-memory`}</code>{`という概念があるので注意が必要です。`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`maxmemory`}</code>{` はインスタンスタイプによって固定値に設定されている`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`maxmemory`}</code>{` は`}<strong parentName="li">{`変更できない`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`代わりに `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`reserved-memory`}</code>{` or `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`reserved-memory-percent`}</code>{` を設定して Eviction 発火メモリ量を設定できる`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`maxmemory`}</code>{` - `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`reserved-memory`}</code>{` < `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`used_memory`}</code>{` で Eviction が発火する`}</li>
        </ul>
      </li>
      <li parentName="ul">{`古めのインスタンス（2017 年 3 月 16 日以前作成）では `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`reserved-memory`}</code>{` がパラメータとして利用できるが、`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`reserved-memory`}</code>{` のデフォルト値は 0byte`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`reserved-memory-percent`}</code>{` のデフォルト値は 25%`}</li>
        </ul>
      </li>
      <li parentName="ul">{`この周辺の AWS 公式ドキュメントは`}<a parentName="li" {...{
          "href": "https://docs.aws.amazon.com/ja_jp/AmazonElastiCache/latest/UserGuide/redis-memory-management.html"
        }}>{`こちら`}</a></li>
    </ul>
    <p>{`ジョブメドレーでは単一の ElastiCache for Redis インスタンスで運用対象を減らす戦略を取っています（Redis 本家では用途別に分けて適切にチューニングすることを推奨しているため、将来的にはこの構成は変わるかもしれません）。そのため、キャッシュ利用のキーには必ず TTL を設定し、Eviction policy は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`volatile-lru`}</code>{`としています。
そして、少し余裕をもたせて `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reserved-memory`}</code>{` を設定、Evictions メトリクスの監視をしています。`}</p>
    <h1>{`メモリ利用量を SQL で分析する`}</h1>
    <p>{`現在稼働している本番環境上でどのパターンのキーがどの程度のメモリを使っているかを把握したくなる場面に出くわした方もいらっしゃるのではないでしょうか。`}</p>
    <p>{`ElastiCache for Redis では簡単に RDB スナップショットを取得することができます。このデータをうまく使えば直接本番稼動のインスタンスを触りにいくことなく、手元で安心して分析作業が実施できます。`}</p>
    <p>{`この分析作業に便利なのが `}<a parentName="p" {...{
        "href": "https://github.com/sripathikrishnan/redis-rdb-tools"
      }}>{`redis-rdb-tools`}</a>{` です。このツールは RDB ファイルの内容をもとにキーごとの byte 値を以下のような CSV に出力することができます（`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`size_in_bytes`}</code>{`は理論値）。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ rdb -c memory dump.rdb `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` memory.csv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` memory.csv

database,type,key,size_in_bytes,encoding,num_elements,len_largest_element
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`,list,lizards,241,quicklist,5,19
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`,hash,baloon,138,ziplist,3,11`}</code></pre></div>
    <p>{`この csv を以下のように SQLite 等のデータベースに突っ込むことで手元で SQL を使ってメモリ統計の分析ができるようになります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ rdb -c memory dump.rdb `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` memory.csv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
$ sqlite3 memory.db
sqlite`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` create table memory`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`database int,type varchar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`128`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`,key varchar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`128`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`,size_in_bytes int,encoding varchar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`128`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`,num_elements int,len_largest_element varchar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`128`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`))`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
sqlite`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`.mode csv memory
sqlite`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`.import memory.csv memory`}</code></pre></div>
    <p>{`ざっくりとした分析の流れは`}</p>
    <ol>
      <li parentName="ol">{`本番環境の daily backup RDB 取得`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`redis-rdb-tools`}</code>{`で csv 出力`}</li>
      <li parentName="ol">{`csv を sqlite に csv format で import`}</li>
      <li parentName="ol">{`SQL で分析`}</li>
    </ol>
    <p>{`のようになります。データベースにインポートさえ出来てしまえば以下のように様々な分析が可能になります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`sqlite`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`select`}</span>{` sum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`size_in_bytes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` from memory where key like `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'%cells%'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
XXXXX
sqlite`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`select`}</span>{` count`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`*`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` from memory where key like `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'%cells%'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
XXXXX`}</code></pre></div>
    <p>{`注意点としては実際に本番稼動しているメモリ量を取得しているわけではないので実測値と値がずれてくることです（感覚的には理論値は実測値の 1/2 ぐらいでした）。こちらの詳細の推定ロジックが気になる方は`}<a parentName="p" {...{
        "href": "https://github.com/sripathikrishnan/redis-rdb-tools/blob/master/rdbtools/memprofiler.py"
      }}>{`実装`}</a>{`を確認いただければと思います。`}</p>
    <p>{`ジョブメドレーではこの手法を使って分析することでアプリロジックを修正して不要なメモリ利用の改善等に役立てています。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`ElastiCache for Redis のメモリ周りを中心とした運用ネタについて紹介しました。`}</p>
    <p>{`ElastiCache for Redis はとても便利でシュッと設定してそれなりの規模でもなんとなく運用できてしまう手軽さがありますが、油断していると思わぬ落とし穴にはまることもあります。`}</p>
    <p>{`本記事がみなさまの ElastiCache for Redis 運用ライフの一助になれば幸いです。`}</p>
    <p>{`また、ジョブメドレーをはじめ、メドレーの開発にご興味ある方は、ぜひご連絡ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>
    <p>{`メドレーが LT スポンサーを務める RubyKaigi2018 にもお邪魔する予定（たまにブースに立っています）ので、そちらでもお会いしましょう。`}</p>
    <p><a parentName="p" {...{
        "href": "https://rubykaigi.org/2018"
      }}>{`https://rubykaigi.org/2018`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      